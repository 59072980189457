import React from "react";
import PropTypes from "prop-types";
import { SectionTitleWrapper } from "./styled";

const SectionTitle = ({
  fsize,
  color,
  talign,
  children,
  ptop,
  pbottom,
  pleft,
  pright,
  textStyle,
}) => (
  <SectionTitleWrapper
    fsize={fsize}
    color={color}
    talign={talign}
    ptop={ptop}
    pbottom={pbottom}
    pleft={pleft}
    pright={pright}
    textstyle={textStyle}
  >
    {children}
  </SectionTitleWrapper>
);

SectionTitle.propTypes = {
  fsize: PropTypes.string,
  color: PropTypes.string,
  talign: PropTypes.string,
  ptop: PropTypes.string,
  pbottom: PropTypes.string,
  pleft: PropTypes.string,
  pright: PropTypes.string,
  children: PropTypes.node,
};

SectionTitle.defaultProps = {
  talign: "center",
};

export default SectionTitle;
