import React from "react";
import { withTheme } from "styled-components";
import { SectionTitle } from "../../../components/Titles";
import Step from "./Step";
import { Container, StepsWrap, SectionTitleWrap } from "./styled";
import { stepsData } from "./_steps";

const StepsSection = ({ theme }) => {
  return (
    <Container>
      <SectionTitleWrap>
        <SectionTitle textStyle={theme.textStyles.sectionTitle}>
          Your custom Kitchen and Bath is just a few steps away!
        </SectionTitle>
      </SectionTitleWrap>
      <StepsWrap>
        {stepsData.map((step, key) => (
          <Step
            key={key}
            iconImg={step.iconImg}
            title={step.title}
            summary={step.summary}
          />
        ))}
      </StepsWrap>
    </Container>
  );
};

export default withTheme(StepsSection);
