import styled from "styled-components";

export const SectionTitleWrapper = styled.div`
  ${({ theme, textstyle }) =>
    textstyle ? textstyle : theme.textStyles.title1};
  ${({ fsize }) => fsize && `font-size: ${fsize}px`};
  ${({ color }) => color && `color: ${color}`};
  ${({ talign }) => talign && `text-align: ${talign}`};
  ${({ ptop }) => ptop && `padding-top: ${ptop}`};
  ${({ pbottom }) => pbottom && `padding-bottom: ${pbottom}`};
  ${({ pleft }) => pleft && `padding-left: ${pleft}`};
  ${({ pright }) => pright && `padding-right: ${pright}`};

  @media only screen and (max-width: 560px) {
    font-size: 16px;
  }
`;
