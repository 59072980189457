export const stepsData = [
  {
    id: 1,
    iconImg: "/icons/step_calendar.png",
    title: "1. Schedule a free consultation",
    summary:
      "Schedule a time to meet with one of our designers to get started on your design.",
  },
  {
    id: 2,
    iconImg: "/icons/step_consultation.png",
    title: "2. Meet the Designer",
    summary:
      "Meet with a designer. Together you will go over the vision for your space, measurements, and budget.",
  },
  {
    id: 3,
    iconImg: "/icons/step_design.png",
    title: "3. In-showroom design review",
    summary:
      "Based on your requirements, your designer will create a custom design, review it with you, and make any necessary changes.",
  },
  {
    id: 4,
    iconImg: "/icons/step_installation.png",
    title: "4. Creation and installation",
    summary: "Our professionals will create and install your design on a scheduled date.",
  },
  {
    id: 5,
    iconImg: "/icons/step_enjoy.png",
    title: "5. Enjoy your new space!",
    summary: "Enjoy your new home storage solution for years to come!",
  },
];
